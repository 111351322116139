import { ApplicationRef, Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppConfigService } from 'src/app/core/app-config.service';
import { Title } from '@angular/platform-browser';
import { HeaderService } from './header/header.service';
import { UtilitiesService } from './services/shared/utilities-service';
import { StartUpService } from './services/start-up/start-up.service';
import { WorkspaceService } from './workspace/workspace.service';
import { AppInsightMonitoringService } from './services/shared/app-insight-monitoring.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'portal-ui';
  opened: boolean;
  isMobile = false;
  featureList: any[];
  isGdprBannerAvailable: boolean;
  isOtherPage = false;
  isSolo = false;
  tHiLight: any;
  isEOLPage = false;
  isClafSupport = false;
  @ViewChild('sidenav') sideNav: ElementRef;
  constructor(
    applicationRef: ApplicationRef,
    private zone: NgZone,
    private router: Router,
    private appConfigService: AppConfigService,
    private titleService: Title,
    private headerservice: HeaderService,
    private utilitiesService: UtilitiesService,
    private workspaceService: WorkspaceService,
    private startUpService: StartUpService,
    private MonitoringService: AppInsightMonitoringService,
    private renderer: Renderer2) {

    // Bug 5475788: Azure: User gets directed to the Home page after pressing the Refresh page button on the Admin page. - START
    // Initial navigation is moved from app-routing.module.ts to here. More details in Bug 5475788
    router.initialNavigation();
    // Bug 5475788: Azure: User gets directed to the Home page after pressing the Refresh page button on the Admin page. - END

    // read query param
    const soloQryRt = this.processSoloQueryString();
    if (soloQryRt)
    {
      router.navigate([soloQryRt]);
    }

    if (window.location.href.includes('/Platform/browserEOL')) {
      this.isEOLPage = true;
    }
    router.events.subscribe((uri) => {
      zone.run(() => applicationRef.tick());
    });
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.checkCurrentPage(router.url);
      }
    });

    this.router.events.subscribe (
      (event: any) => {
        if (event instanceof NavigationEnd) {
          if (this.router.url.toString().includes('/Platform/claf-support?bpnWebPage') || this.router.url.toString().includes('/Platform/claf-support')) {
            this.isClafSupport = true;
          }else{
            this.isClafSupport = false;
          }
        }
        });
      }

  processSoloQueryString(): string
  {
    let soloMode = null;
    const soloRegx = '[\?&]solo=([^&#]*)';
    const soloMatch = new RegExp(soloRegx).exec(window.location.search);
    if (soloMatch !== null)
    {
        soloMode = decodeURI(soloMatch[1]) || 0;
    }
    if (soloMode === 'pieWidget')
    {
      return this.appConfigService.getRoute('pieHome');
    }
    return null;
  }

  ngOnInit() {
    this.initializeData();
  }



  initializeData() {
    this.headerservice.openSideMenuValue.subscribe((response: any) => {
      this.opened = response;
    });
    this.appConfigService.fetchComponentConfig('featureSwitch-mbf').subscribe(result => {
      this.featureList = result;
      const featureName = 'gdprBanner';
      const gdprBannerFeature = this.featureList[featureName];
      if (gdprBannerFeature && gdprBannerFeature.active) {
        this.isGdprBannerAvailable = true;
      } else {
        this.isGdprBannerAvailable = false;
      }
    });
    this.zone.runOutsideAngular(() => {
      window.addEventListener('resize', this.onResize.bind(this));
      document.addEventListener('click', this.documentClick.bind(this));
      this.onResize();
      window.addEventListener('popstate', this.onPopStateChanged.bind(this), false);
      window.addEventListener('scroll', this.scrollEvent, true);
    });
  }

  scrollEvent = (event): void => {
    const scrollTopVal = event.target.scrollingElement?.scrollTop;
    const scrollHeight = event.target.scrollingElement?.scrollHeight;
    const divElement = this.sideNav.nativeElement as HTMLElement;
    const sideNavElement = divElement?.firstChild;
    const reachedFooter = scrollHeight - scrollTopVal - 1100;
    if(sideNavElement && reachedFooter < 0){
      this.renderer.addClass(sideNavElement,'posSticky');
    } else if(sideNavElement){
      this.renderer.removeClass(sideNavElement,'posSticky');
    }
  }

  changeOfRoutes() {
    if (window.location.href.includes('/Platform/workspace/home')) {
      this.headerservice.setWorkspaceLockSts(false);
    }
    else if (window.location.href.includes('/Solo'))
    {
      // do nothing
    }
    else if (!window.location.href.includes('/Platform/workspace/')) {
      this.workspaceService.getDefaultWorkspace().subscribe((workspace: any) => {
        if (workspace) {
          this.headerservice.setWorkspaceLockSts(workspace.data.locked);
        }
      });
    }
  }

  documentClick(event: any): void {
    this.utilitiesService.documentClickedTarget.next(event);
  }

  checkCurrentPage(url: string) {
    if (this.router.url.indexOf('/Login') !== -1) {
      this.isOtherPage = false;
    } else {
      this.isOtherPage = true;
      this.titleService.setTitle(this.appConfigService.getProperty('pageTitle'));
    }

    if (this.router.url.indexOf('/Solo') !== -1) {
      this.isSolo = true;
    } else {
      this.isSolo = false;
    }
  }

  closeFunc() {
    this.headerservice.setopenNotificationData(false);
    this.headerservice.setopenSideMenu(false);
  }

  backDropFun(): any {
    if (window.innerWidth > 991) {
      return false;
    }
  }

  onResize() {
    this.checkMobile();
  }

  onPopStateChanged() {
    this.tHiLight = document.getElementById('tutorial-highlight');
    this.tHiLight.style.display = 'none';
    document.getElementById('htmlContent').style.overflow = 'auto';
  }

  checkMobile() {
    if (window.innerWidth <= 991) {
      if (this.isMobile !== true) {
        this.zone.run(() => {
          this.isMobile = true;
        });
      }
    } else {
      if (this.isMobile !== false) {
        this.zone.run(() => {
          this.isMobile = false;
        });
      }
    }
  }

}
