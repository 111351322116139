import { Component, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { AppConfigService } from '../core/app-config.service';
import { HeaderData, HeaderMenu, HeaderSubmenu } from '../header/header-data';
import { HeaderService } from '../header/header.service';
import { UserApiService } from '../user/user-api.service';
import { User } from '../user/user';
import { UserRolesItem } from '../user/user-roles-item';
import { NavigationEnd, Router } from '@angular/router';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { UserPortalMessageFormat } from '../header/user-portal-message-interface';
import {
  SystemMessageDialogBoxComponent
} from '../admin/system-message/components/system-messages-display-view/system-message-dialog-box/system-message-dialog-box.component';
import { memoize, values } from 'lodash';
import { AddFolderDialogComponent } from '../workspace-widget/basic-folder/add-folder-dialog/add-folder-dialog.component';
import { AddLinkDialogComponent } from '../workspace-widget/drag-drop/add-link-dialog/add-link-dialog.component';
import { ViewFolderArchiveDialogComponent } from '../workspace-options/view-folder-archive-dialog/view-folder-archive-dialog.component';
import {
  ResetHomeToDefaultDialogComponent
} from '../workspace-options/reset-home-to-default-dialog/reset-home-to-default-dialog.component';
import { WorkspaceService } from '../workspace/workspace.service';
import { Workspace } from '../workspace/workspace';
import { PollingTimerService } from '../services/shared/polling-timer/polling-timer.service';
import { PollingItem } from '../services/shared/polling-timer/polling-interface';
import { TutorialDialogComponent } from '../header/tutorial-dialog/tutorial-dialog.component';
import { SearchResultsService } from '../services/search-results/search-results.service';
import { SearchResultsTutorialDialogComponent } from '../header/search-results-tutorial-dialog/search-results-tutorial-dialog.component';
import { ApplistTutorialDialogComponent } from '../header/applist-tutorial-dialog/applist-tutorial-dialog.component';
import { LogoutService } from '../services/logout/logout.service';

@Component({
  selector: 'app-header-mbf-sidenav',
  templateUrl: './header-mbf-sidenav.component.html',
  styleUrls: ['./header-mbf-sidenav.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderMbfSidenavComponent implements OnInit {
  @ViewChild('notificationTrigger') notificationTrigger: MatMenuTrigger;
  @ViewChild('accountTrigger') accountTrigger: MatMenuTrigger;
  @ViewChild('linkTrigger') linkTrigger: MatMenuTrigger;
  envConfig = {};
  communityBase: string;
  headerData: HeaderData;
  primaryMenu = [] as HeaderMenu[];
  secondaryMenu: HeaderMenu[] = [] as HeaderMenu[];
  secondaryBannerLeft = [] as HeaderSubmenu[];
  helpItems = [] as HeaderSubmenu[];
  dashboardItems = [] as HeaderSubmenu[];
  communitiesItems = [] as HeaderSubmenu[];
  displayHelpOptions = false;
  displayMenuOptions = false;
  displayNotificationOptions = false;
  displayActionOptions = false;
  currentTab = '';
  dismissJobRoleSurvey = false;
  accessAdministratorFlag = false;
  yourAccountLink = '';
  user: User = {
    firstName: '',
    lastName: '',
    userRoles: [] as UserRolesItem[]
  } as User;
  notifications: UserPortalMessageFormat[] = [] as UserPortalMessageFormat[];
  unreadMessages: UserPortalMessageFormat[] = [];
  userNotificationMessages: UserPortalMessageFormat[] = [];
  unreadNotifications = 0;
  unreadNotificationsAvailable = false;
  undismissedNotificationsAvailable = false;
  mbfHome = false;
  prtlWelcomeMsgPrefixNew: string;
  prtlWelcomeMsgPrefix: string;
  prtlWelcomeMsgSuffix: string;
  profileMenuDefaultMsg: string;
  profileMenuNameMsg: string;
  profileMenuMessage: string;
  profileEmailAddress: string;
  logoutConfirmed = false;
  showSnackbar = true;
  isRefresh = false;
  snackBarDismiss = true;
  workspaceLocked = false;
  isMobile = false;
  pollingItems: PollingItem[] = [{ key: 'notifications', callback: () => { this.refreshUserAndWorkspaces(); } }];
  memoizeGetmessageType = memoize(this.getNotificationsHeader, (...args) => values(args).join(''));
  memoizeGetBuildUrl = memoize((item: HeaderSubmenu) => this.buildUrl(item), (...args) => args[0].title);

  constructor(
    public cd: ChangeDetectorRef,
    public headerService: HeaderService,
    public appConfigService: AppConfigService,
    public userApiService: UserApiService,
    public workspaceService: WorkspaceService,
    public router: Router,
    public snackBar: MatSnackBar,
    public location: Location,
    public matDialog: MatDialog,
    private searchResultsService: SearchResultsService,
    private pollingTimerService: PollingTimerService,
    private zone: NgZone,
    public logoutService: LogoutService) {
      
    }

  ngOnInit(): void {
    this.communityBase = '#/Platform/workspace/';
    this.appConfigService.envConfig.pipe(
      tap(config => { this.envConfig = config; }),
      switchMap(config => this.headerService.getHeaderData(config[`oemPlatformAcronym`]))
    ).subscribe((response: HeaderData) => {
      if (response) {
        this.headerData = response;
        this.primaryMenu = response.primaryMenu;
        this.secondaryMenu = response.secondaryMenu;
        this.secondaryBannerLeft = this.headerData.secondaryBannerLeft
          .filter(item => !(item.title === `APIs` && this.envConfig[`airlineCode`] !== `TBC`));
        this.helpItems = this.headerData.help;
        this.dashboardItems = this.headerData.dashboard;
      }
      this.cd.markForCheck();
    });
    this.userApiService.getUser().subscribe((user: User) => {
      this.user = user;
      this.setupInitialMessage();
    });
    this.checkAccessAdminiPriviledge();
    this.getYourAccountLinks();
    this.setMbfHome();
    this.router.events.subscribe(val => {
      this.setMbfHome();
      this.cd.markForCheck();
    });
    const workspaceList = this.workspaceService.getWorkspacesProp(true);
    workspaceList.subscribe(workspaces => {
      if (workspaces.data && Array.isArray(workspaces.data)) {
        this.communitiesItems = workspaces.data.filter((workspace: Workspace) => workspace.unlisted === false)
          .map((workspace: Workspace) => this.buildWorkspaceLink(workspace));
      }
    });
    this.refreshUserAndWorkspace();
    this.pollingTimerService.registerPolling(this.pollingItems);
    this.setCurrentPage(this.router.url);
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setCurrentPage(this.router.url);
      }
    });
    this.headerSideMenuBar();
    this.headerService.undismissedNotificationsAvailable.subscribe((data) => {
      this.undismissedNotificationsAvailable = data;
      this.cd.markForCheck();
    });
  }

  refreshUserAndWorkspace(): void {
    this.userApiService.getUserAndWorkspace().subscribe((user: User) => {
      this.user = user;
      this.setupProfileMenuMessage();
      this.getNotifications();
      this.cd.markForCheck();
    });
  }
  checkMobile() {
    if (window.innerWidth <= 991) {
      if (this.isMobile !== true) {
        this.zone.run(() => {
          this.isMobile = true;
        });
      }
      return true;
    } else {
      if (this.isMobile !== false) {
        this.zone.run(() => {
          this.isMobile = false;
        });
      }
      return false;
    }
  }

  refreshUserAndWorkspaces(): void {
    this.userApiService.getUserAndWorkspace().subscribe((user: User) => {
      this.user = user;
      this.setupProfileMenuMessage();
      this.showSnackbar = true;
      this.snackBar.dismiss();
      this.getNotifications();
      this.headerService.setNotificationsUpdated(true);
      this.cd.markForCheck();
    });
  }

  buildWorkspaceLink(workspace: Workspace) {
    return {
      title: workspace.longName,
      url: `${this.communityBase}${workspace.id}`,
      urlTarget: '', type: '',
      shortName: workspace.shortName
    };
  }

  /**
   * Hide the activated menu options and show the default options
   */
  closeSideMenu(): void {
    this.headerService.setopenSideMenu(false);
    this.displayHelpOptions = false;
    this.displayNotificationOptions = false;
    this.displayMenuOptions = false;
    this.displayActionOptions = false;
    this.headerService.setopenNotificationData(false);
    this.cd.markForCheck();
  }

  /**
   * On click of link icon Close the account menu
   */
  closeAccountMenu() {
    if (this.accountTrigger.menuOpen) {
      this.accountTrigger.closeMenu();
      this.cd.markForCheck();
    }
  }
  closeLinkMenu() {
    if (this.linkTrigger.menuOpen) {
      this.linkTrigger.closeMenu();
      this.cd.markForCheck();
    }
  }
  setCurrentPage(url: string) {
    if (url === '/' || url.indexOf('Platform/workspace') !== -1) {
      this.currentTab = 'MBF Home';
    }
    else if (url.indexOf('/Admin') !== -1) {
      this.currentTab = 'Admin';
    } else if (url.indexOf('Platform/apps') !== -1) {
      this.currentTab = 'Applications';
    } else if (url.indexOf('YourAccount') !== -1) {
      this.currentTab = 'YourAccount';
    } else {
      this.currentTab = '';
    }
    if (url.indexOf('Platform/jobRoleSurvey') !== -1) {
      this.dismissJobRoleSurvey = true;
    } else {
      this.dismissJobRoleSurvey = false;
    }
  }

  IfAdminLink(item: HeaderSubmenu) {
    return this.showAdminLinkfromString(item.title);
  }

  showAdminLinkfromString(title: string = '') {
    if (title.toLowerCase() === 'admin') {
      if (this.user.userRoles.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  notificationOptions(value: boolean): void {
    this.displayMenuOptions = value;
    this.displayNotificationOptions = value;
  }

  actionOptions(value: boolean): void {
    this.displayMenuOptions = value;
    this.displayActionOptions = value;
  }
  displayHelp(value: boolean): void {
    this.displayMenuOptions = value;
    this.displayHelpOptions = value;
  }

  buildUrl(item: HeaderSubmenu) {
    let url = item.url;
    // if (item.title === this.appConfigService.getProperty(`partpageTitle`)) {
    //   url = this.envConfig[`partpageUrl`];
    // }
    if (item.title === this.appConfigService.getProperty(`mbfSupportPageTitle`)) {
      url = `#/Platform/psp/${this.envConfig['mbfPspId']}`;
    }
    if (item.title === 'Access Administrator Support') {
      url = this.buildAccessAdminLink(item);
    }
    if (item.title === 'Product and Data Access (Access Summary)') {
      url = this.buildAccessSummaryLink();
    }
    if (item.title === 'Introduction to MBF') {
      // tslint:disable-next-line: no-string-literal
      url = this.envConfig['mbfIntroUrl'];
    }
    return this.buildUrlFromString(url, item.type);
  }
  buildAccessAdminLink(item: HeaderSubmenu) {
    let url = '';
    const accessAdminUrl = 'accessAdminUrl';
    const mbfAdminAccessId = this.envConfig[accessAdminUrl];
    url = `#/Platform/psp/${mbfAdminAccessId}`;
    return url;
  }
  buildAccessSummaryLink() {
    let url = '';
    const accessSummaryUrl = 'accessSummaryUrl';
    url = this.envConfig[accessSummaryUrl];
    return url;
  }

  buildUrlFromString(url: string = '', type: string) {
    return this.headerService.buildUrlFromString(url, type, this.envConfig);
  }
  buildMbfSupportPageUrl(item: HeaderSubmenu): string {
    let url = '';
    const mbfPspIdString = 'mbfPspId';
    const mbfPspId = this.envConfig[mbfPspIdString];
    switch (this.appConfigService.getProperty(`mbfSupportPageTarget`)) {
      case 'portal_ng':
        item.type = 'portal_ng';
        url = `index.html#/Platform/apps/${mbfPspId}`;
        break;
      case 'portal-ui':
      default:
        item.type = 'portal-ui';
        url = `#/Platform/psp/${mbfPspId}`;
        break;
    }
    return url;
  }

  setMbfHome() {
    this.mbfHome = (this.location.path() === ''
      || this.location.path() === this.appConfigService.getRoute(`mbfHome`)
      || this.location.path() === this.appConfigService.getRoute(`mbfHomeWorkspace`)
    );
  }

  openLogoutModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      type: 'error',
      title: 'Are you sure you would like to logout?',
      actions: [{ func: 'closeModal', text: 'No' }, { func: 'actionFunction', text: 'Yes' }]
    };
    const logoutDialog = this.matDialog.open(ConfirmationModalComponent, dialogConfig);
    logoutDialog.afterClosed().subscribe(logoutConfirmed => {
      this.logoutConfirmed = logoutConfirmed;
      if (this.logoutConfirmed) {
        this.snackBar.dismiss();
        this.headerService.setLogoutClicked(true);
        this.logoutUser();
      }
    });
  }

  private logoutUser(): void {
    this.logoutService.logout(true);
  }

  /**
   * Method to handle Notification details pop up
   * @param action Type of Action from Dialog
   * @param item Notification details
   */
  openNotificationDetailsDialog(action: string, item: UserPortalMessageFormat) {
    this.closeSideMenu();
    const dataToDialog: any = item;
    dataToDialog.action = action;
    const dialogRef = this.matDialog.open(SystemMessageDialogBoxComponent, {
      data: dataToDialog
    });
    if (!item.messageRead) {
      this.headerService.markAsReadNotification(item.messageId).subscribe((response: any) => {
        this.getNotifications();
      });
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Details') {
        this.deleteNotification(result.dialogData);
      }
      this.cd.markForCheck();
    });
  }

  /**
   * Method to fetch the notifications
   */
  getNotifications(): void {
    this.headerService.getActiveNotifications(false).subscribe((response: any) => {
      this.notifications = [];
      this.unreadMessages = [];
      this.userNotificationMessages = [];
      const unreadNotifications: UserPortalMessageFormat[] = [];
      const plannedSystemMessages: UserPortalMessageFormat[] = [];
      const unPlannedSystemMessages: UserPortalMessageFormat[] = [];
      const infoSystemMessages: UserPortalMessageFormat[] = [];
      if (response) {
        for (const notification of response) {
          if (!notification.messageRead) {
            unreadNotifications.push(notification);
          }
          if (notification.messageType === 'UNPLANNED_OUTAGE') {
            unPlannedSystemMessages.push(notification);
          } else if (notification.messageType === 'PLANNED_OUTAGE') {
            plannedSystemMessages.push(notification);
          } else if (notification.messageType === 'INFORMATIONAL') {
            infoSystemMessages.push(notification);
          } else if (notification.messageType === 'USER_NOTIFICATION') {
            this.userNotificationMessages.push(notification);
          } else if (notification.messageType === 'TRAINING') {
            this.userNotificationMessages.push(notification);
          } else if (notification.messageType === 'ACTION_REQUIRED') {
            this.userNotificationMessages.push(notification);
          }
        }
      }
      this.notifications = unPlannedSystemMessages.concat(plannedSystemMessages)
        .concat(infoSystemMessages).concat(this.userNotificationMessages);
      this.unreadMessages = unreadNotifications;
      this.updateCount();
      this.isRefresh = false;
      this.cd.markForCheck();
    });
  }

  updateCount(): void {
    this.unreadNotifications = this.unreadMessages.length;
    this.unreadNotificationsAvailable = this.unreadNotifications > 0;
  }

  /**
   * Delete the User Notification
   * @param item Message Id
   */
  deleteNotification(item: UserPortalMessageFormat): void {
    this.isRefresh = true;
    this.headerService.deleteNotification(item.messageId).subscribe((response: any) => {
      this.getNotifications();
    });
  }

  openNotificationsOptions(): void {
    window.scroll({
      top: 0,
      left: 0
    });
    this.headerService.setopenNotificationData(true);
    this.headerService.setopenSideMenu(true);
    this.displayMenuOptions = true;
    this.displayNotificationOptions = true;
    this.displayHelpOptions = false;
    this.displayActionOptions = false;
  }

  /**
   * Method to get the Headers that should be displayed inside Notification drop down list
   * @param notificationDetails Notification details
   */
  getNotificationsHeader(messageType: string): string {
    if (messageType === 'UNPLANNED_OUTAGE') {
      return 'UNPLANNED OUTAGE';
    } else if (messageType === 'PLANNED_OUTAGE') {
      return 'PLANNED OUTAGE';
    } else if (messageType === 'USER_NOTIFICATION' || messageType === 'INFORMATIONAL') {
      return 'INFORMATION';
    } else if (messageType === 'TRAINING') {
      return 'TRAINING';
    } else if (messageType === 'ACTION_REQUIRED') {
      return 'ACTION REQUIRED';
    }
  }

  clearUpdates(): void {
    this.isRefresh = true;
    const messageIds = this.unreadMessages.map(a => a.messageId);
    this.headerService.clearUpdates(messageIds).subscribe((response: any) => {
      this.getNotifications();
    });
  }

  openDashboardDialog(item: HeaderSubmenu) {
    this.closeSideMenu();
    if (item.title === 'Add a folder') {
      const obj = {
        action: 'Add'
      };
      this.matDialog.open(AddFolderDialogComponent, {
        data: obj,
        disableClose: true
      });
    } else if (item.title === 'Add a link') {
      const obj = {
        action: 'Add'
      };
      this.matDialog.open(AddLinkDialogComponent, {
        data: obj,
        disableClose: true
      });
    } else if (item.title === 'View folder archive') {
      this.matDialog.open(ViewFolderArchiveDialogComponent);
    } else if (item.title === 'Reset home page to default') {
      this.matDialog.open(ResetHomeToDefaultDialogComponent, {
        disableClose: true,
        autoFocus: false
      });
    }
  }

  setupInitialMessage() {
    this.prtlWelcomeMsgPrefixNew = this.appConfigService.getProperty(`prtlWelcomeMsgPrefixNew`);
    this.prtlWelcomeMsgPrefix = this.appConfigService.getProperty(`prtlWelcomeMsgPrefix`);
    this.prtlWelcomeMsgSuffix = this.appConfigService.getProperty(`prtlWelcomeMsgSuffix`);
    this.profileMenuDefaultMsg = this.appConfigService.getProperty(`profileMenuDefaultMsg`);
    this.profileMenuNameMsg = this.appConfigService.getProperty(`profileMenuNameMsg`);
    this.setupProfileMenuMessage();
  }

  setupProfileMenuMessage() {
    this.profileMenuMessage = (this.profileMenuDefaultMsg) ? this.profileMenuDefaultMsg : 'Hello!';
    this.profileEmailAddress = '';
    if (this.user && this.user.fullName) {
      this.profileMenuMessage = this.user.fullName;
      this.profileEmailAddress = this.user.emailAddress;
    } else if (this.user && this.user.firstName) {
      this.profileMenuMessage = this.user.firstName;
      this.profileEmailAddress = this.user.emailAddress;
    }
  }

  headerSideMenuBar() {
    // To open the side menu bar
    this.headerService.openSideMenuValue.subscribe((response: any) => {
      if (response) {
        if (this.headerService.openNotificationData.value) {
          // Open the side menu bar with notifications
          this.displayMenuOptions = true;
          this.displayNotificationOptions = true;
          this.displayHelpOptions = false;
          this.snackBarDismiss = false;
          this.displayActionOptions = false;
        } else {
          // open the side menu bar with default menu options
          this.displayHelpOptions = false;
          this.displayNotificationOptions = false;
          this.displayMenuOptions = false;
          this.displayActionOptions = false;
        }
      } else {
        if (this.accountTrigger) {
          this.accountTrigger.closeMenu();
          this.linkTrigger.closeMenu();
        }
      }
      this.cd.markForCheck();
    });
    this.headerService.workspaceLockSts.subscribe((response: any) => {
      this.workspaceLocked = response;
    });
  }

  openTutorialDialog(obj) {
    if (window.location.href.includes('/Platform/searchResults')) {
      this.searchResultsService.setTextSearchRequest('Training');
      this.router.navigate(['Platform/searchResults']);
      const dialogRef = this.matDialog.open(SearchResultsTutorialDialogComponent, {
        data: obj, panelClass: 'search-tutorial-dialog-cls',
        disableClose: true, id: 'search-tutorial-dlg'
      });
    } else if (window.location.href.includes('/Platform/apps')) {
      this.matDialog.open(ApplistTutorialDialogComponent, {
        data: obj, panelClass: 'tutorial-dialog-cls',
        disableClose: true, id: 'tutorial-dlg'
      });
    } else {
      this.matDialog.open(TutorialDialogComponent, {
        data: obj, panelClass: 'tutorial-dialog-cls',
        disableClose: true, id: 'tutorial-dlg'
      });
    }
  }
  checkAccessAdminiPriviledge() {
    let envConfigAccessAdmin = '';
    const environmentString = 'environment';
    const accessAdminUrl = 'accessAdminUrl';
    this.appConfigService.envConfig.subscribe(config => {
     if (config[accessAdminUrl]) {
       this.envConfig = config;
       envConfigAccessAdmin = config[accessAdminUrl];
       if (envConfigAccessAdmin === '') {
         this.accessAdministratorFlag = false;
       }
       else {
         this.accessAdministratorFlag = true;
       }
     }
     else {
       this.accessAdministratorFlag = false;
     }
   });
  }
  getYourAccountLinks() {
    let env: string = "prod";
    this.yourAccountLink = 'https://accounts.boeing.com';
    const environmentString = 'environment';
    this.appConfigService.envConfig.subscribe(config => {
      if (config[environmentString]) {
        env = config[environmentString];
      }

      this.appConfigService.fetchComponentConfig('your-account-links').subscribe((result: any) => {
        const envLink = result.yourAccountLinks;
        this.yourAccountLink = envLink[env] ? envLink[env] : this.yourAccountLink;
      });
    });
  }
}

