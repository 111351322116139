import { Injectable } from '@angular/core';
import { WorkspaceService } from 'src/app/workspace/workspace.service';
import { AppConfigService } from 'src/app/core/app-config.service';
import { Router, NavigationEnd, Event } from '@angular/router';
import { cloneDeep, merge } from 'lodash';
import { take } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { EventCatelogService } from 'src/app/admin/event-catelog-admin/event-catelog-service.service';
import { WidgetService } from '../../../workspace-widget/widget.service';
import { MarketingWidget } from '../../../workspace-widget/marketing-widget/marketing-widget';
import { EventCatalogSharedService } from '../event-catalog/event-catalog-shared.service';


@Injectable({
  providedIn: 'root'
})
export class AnalyticTaggingService {
  digitalDataBase: {};
  analyticTags: any;
  trackAnalytics = false;
  digitalData: any;
  defaultEventObj = { action: 'action', event: {} };
  log = false;
  noAction = 'noAction';
  skipUrls = [];
  pendingAnalyticsQueue = [];
  marketingWidgetContent$: MarketingWidget[];

  constructor(private router: Router, private workspaceService: WorkspaceService, private appConfigService: AppConfigService,
              private eventCatelogService: EventCatelogService,  private eventCatalogSharedService: EventCatalogSharedService,
              private widgetService: WidgetService) {
    this.getAdobeData('adobe-data').subscribe((response: any) => {
      this.analyticTags = response;
    });
    this.router.events.subscribe((routeEvent) => { this.onRouteEvent(routeEvent); });
    this.appConfigService.envConfig.subscribe(config => {
      // tslint:disable-next-line: no-string-literal
      if (config['trackAnalytics']) {
        // tslint:disable-next-line: no-string-literal
        this.trackAnalytics = (config['trackAnalytics'].toUpperCase() === 'TRUE') ? true : false;
      }
      // tslint:disable-next-line: no-string-literal
      if (this.trackAnalytics && config['environment']) {
        this.processAnalyticsQueue();
      }
      // tslint:disable-next-line: no-string-literal
      if (this.trackAnalytics && config['digitalDataBase']) {
        // tslint:disable-next-line: no-string-literal
        this.digitalDataBase = JSON.parse(config['digitalDataBase']);
        // tslint:disable-next-line: no-string-literal
        this.digitalDataBase['page']['pageInfo']['version'] = 'portal-ui:' + this.getProperty('portal-ui-buildTag') +
          // tslint:disable-next-line: no-string-literal
          ' | portal_ng:' + this.digitalDataBase['page']['pageInfo']['version'] + '';
        this.digitalData = cloneDeep(this.digitalDataBase);
        /* if (this.isPageView()) {
          // tslint:disable-next-line: quotemark
          this.track("pageView");
        } */
      }
    });
  }
  getAdobeData(env: string): Observable<any> {
    return (env !== undefined)
      ? this.appConfigService.fetchComponentConfig(env)
      : EMPTY;
  }
  private onRouteEvent(routeEvent: Event) {
    if (routeEvent instanceof NavigationEnd) {
      if (this.isPageView()) {
        //to prevent page view until entire search results are loaded
        if(routeEvent?.url!='/Platform/searchResults')
        this.track('pageView');
      }
    }
  }
  private isPageView() {
    let isPageView = false;
    try {
      // tslint:disable-next-line: no-string-literal
      isPageView = this.router.routerState.root.snapshot.firstChild.data['analytics'] === undefined ?
        // tslint:disable-next-line: no-string-literal
        this.router.routerState.root.snapshot.firstChild.firstChild.data['analytics']['pageView'] :
        // tslint:disable-next-line: no-string-literal
        this.router.routerState.root.snapshot.firstChild.data['analytics']['pageView'];
    }
    catch (ex) { }
    return isPageView;
  }
  private getProperty(key: string) {
    return this.appConfigService.getProperty(key);
  }
  private processAnalyticsQueue() {
    this.pendingAnalyticsQueue.forEach((callback, index, object) => {
      callback();
      object.splice(index, 1);
    });
  }
  public track(eventId?: any, srcCtrl?: any, obj?: any, srcPage?: any , responseBanner?: MarketingWidget[], workspace?: any) {
    // trackAnalytics defined in envConfig.properties
    if (!this.trackAnalytics || (this.skipUrls.indexOf(window.location.hash) > -1)) {
      this.pendingAnalyticsQueue.push(() => { this.track(eventId, srcCtrl, obj); });
      return;
    }
    if (!srcCtrl.workspace) {
      srcCtrl.workspace = workspace;
    }
    this.sendBacon(eventId, srcCtrl, obj, srcPage, responseBanner);
  }
  private sendBacon(eventId?: any, srcCtrl?: any, obj?: any, srcPage?: any, responseBanner?: MarketingWidget[]) {
    this.workspaceService.getCurrentWorkspace().pipe(take(1)).subscribe((currentWorkspace: any) => {
      // tslint:disable-next-line: no-var-keyword
      var communityNamePrefix = '';
      communityNamePrefix = this.getCommunityNamePrefix(currentWorkspace);
      let eventObj: any = cloneDeep(this.defaultEventObj);
      // eventId may be a function name and/or a key to an entry in the analyticTags map,
      // which will return the appropriate event object
      if (typeof this[eventId] === 'function') {
        eventObj = obj ? this[eventId](eventId, srcCtrl, obj) : this[eventId](eventId, srcCtrl);
      }
      // analyticTags is at end of this file and gives digitalData properties specific to eventId
      else if (this.analyticTags[eventId]) {
        eventObj = cloneDeep(this.analyticTags[eventId]);
      }
      if (eventId === 'bannerCampaignFn'){
        eventObj.event.component[0].type = 'BannerWidget';
      }
      if (eventId === 'widgetCloseLink'){
        eventObj.event.event[0].attributes.linkName = srcCtrl?.data?.longName;
      }
      // event-catelog code
      if (eventId === 'appsRemoveFn') {
        const removeData = this.appsTrack(eventId, srcCtrl);

      }

      try {
        // build digitalData object by copying base data and merging in other event- related objects
        // tslint:disable-next-line: no-var-keyword
        var digitalData = cloneDeep(this.digitalDataBase);
        if (srcPage === 'default' || srcPage === 'home') {
          digitalData = merge(digitalData,
            this.buildPageObj(communityNamePrefix, eventObj),
            this.buildComponentObjHome(srcCtrl, eventObj, obj, responseBanner),
            eventObj.event);
        }
        else{
          digitalData = merge(digitalData,
            this.buildPageObj(communityNamePrefix, eventObj),
            this.buildComponentObj(srcCtrl, eventObj, obj),
            eventObj.event);
        }
        if (eventObj.action !== this.noAction) {

          // tslint:disable-next-line: no-string-literal
          window['digitalData'] = digitalData;
          // tslint:disable-next-line: no-string-literal
          // tslint:disable-next-line: no-string-literal
          window['analytics_et'](eventObj.action, digitalData); // use this or below line one of them
          // window['digitalData'].pageInstanceID=new Date();
        }
        if (eventId === 'LearnMoreLinkSearchAppCardFn'){
          this.eventCatalogSharedService.eventCatalogTracking('Search:Search:' + srcCtrl?.app_shortname,
            eventId, null, srcCtrl.wid);
        }
        if (eventId === 'folderClickFn'){
          this.eventCatalogSharedService.eventCatalogTracking('Home:My Favorites:' + srcCtrl?.shortName, eventId, '',
             null);
        }
      } catch (e) {
      }
      // tslint:disable-next-line: space-before-function-paren
      // tslint:disable-next-line: only-arrow-functions
    }, function(err) {  }); // end then()

  }
  /*
  * build the page.pageInfo.pageName attribute
  * @param {type} communityName
  * @param {type} eventObj
  * @returns pageObject that will be merged into digitalData
  */
  private buildPageObj(communityNamePrefix, eventObj) {

    const pageName = this.router.routerState.root.snapshot.firstChild.data.analytics === undefined ?
      // tslint:disable-next-line: no-string-literal
      this.router.routerState.root.snapshot.firstChild.firstChild.data.analytics['pageName'] :
      // tslint:disable-next-line: no-string-literal
      this.router.routerState.root.snapshot.firstChild.data.analytics['pageName'];
    const pageObj = { page: { pageInfo: { pageName: communityNamePrefix + pageName } } };
    if (eventObj.page && eventObj.page.pageInfo && eventObj.page.pageInfo.pageName) {
      if (eventObj.page.pageInfo.pageNameReplace) {
        pageObj.page.pageInfo.pageName = eventObj.page.pageInfo.pageName;
      }
      else {
        pageObj.page.pageInfo.pageName += eventObj.page.pageInfo.pageName;
      }
    }

    return pageObj;
  }

  /*
   * build the page.component attribute
   * @param {type} components
   * @param {type} eventObj
   * @returns componentObject that will be merged into digitalData
   */
  private buildComponentObj(components, eventObj, obj) {
    const componentObj = { component: [] };
    if (components && components.workspace && components.workspace.type === 'MarketingWidget') {
      componentObj.component.push({
        type: components.workspace.type,
        name: obj.title,
        attributes: {
          dashboard: 'MBF Home'
        }
      });
    } else {
      let workspace;
      if (components && components.workspace) {
        workspace = components.workspace;
      } else if (components && components.layout) {
        workspace = components;
      }
      if ((workspace && workspace.layout && workspace.layout.length > 0)
        || (workspace && workspace.length > 0 && workspace[0].layout && workspace[0].layout.length > 0)) {
        if (components.data || (eventObj.event?.event[0].eventInfo?.eventAction == 'Accessed Learn More')) {
           if (eventObj.event.event[0].eventInfo.eventAction == 'Accessed Learn More') {
            components.data = components.app;
           }
           const newcomponentObj = {
            type: components.data.type,
            name: components.data.shortName ? components.data.shortName : components.data.longName,
            attributes: {
              dashboard: workspace.shortName ? workspace.shortName : workspace.longName ? workspace.longName : 'Home'
            }
          };
           componentObj.component.push(newcomponentObj);
        } else {
          let sortedWorkspace = workspace.layout;
          if (workspace.layout[0].index) {
            sortedWorkspace = this.sortContent(workspace.layout);
          }
          sortedWorkspace.forEach((wItem) => {
            if (wItem.content && wItem.content.layout && wItem.content.layout.length > 0) {
              wItem.content.layout.forEach(element => {
                const newcomponentObj = {
                  type: element.type,
                  name: element.shortName ? element.shortName : element.longName,
                  attributes: {
                    dashboard: wItem.shortName ? wItem.shortName : wItem.longName ? wItem.longName : 'Home'
                  }
                };
                componentObj.component.push(newcomponentObj);
              });
            } else {
              const newcomponentObj = {
                type: wItem.type,
                name: wItem.shortName ? wItem.shortName : wItem.longName,
                attributes: {
                  dashboard: workspace.shortName ? workspace.shortName : workspace.longName ? workspace.longName : 'Home'
                }
              };
              componentObj.component.push(newcomponentObj);
            }
          });
        }
      } else if (eventObj && eventObj.componentReplace) {
        componentObj.component.push(eventObj.component);
      } else {
        componentObj.component = [];
      }
    }

    return componentObj;
  }
  private buildComponentObjHome(components, eventObj, obj, responseBanner) {
    const componentObj = { component: [] };
    if (responseBanner) {
      this.marketingWidgetContent$ = responseBanner;
      this.marketingWidgetContent$?.forEach(marketing => {
        const newcomponentObjBanner = {
          type: marketing?.bannerType === 'top' ? 'BannerWidget' : 'MarketingWidget',
          name: marketing.title,
          attributes: {
            dashboard: 'MBF Home'
          }
        };
        componentObj.component.push(newcomponentObjBanner);
      });

      if (!(components && components.workspace && components.workspace.type === 'MarketingWidget')) {
        let workspace;
        if (components && components.workspace) {
          workspace = components.workspace;
        } else if (components && components.layout) {
          workspace = components;
        }
        if ((workspace && workspace.layout && workspace.layout.length > 0)
          || (workspace && workspace.length > 0 && workspace[0].layout && workspace[0].layout.length > 0)) {
          if (components.data) {
            const newcomponentObj = {
              type: components.data.type,
              name: components.data.shortName ? components.data.shortName : components.data.longName,
              attributes: {
                dashboard: workspace.shortName ? workspace.shortName : workspace.longName ? workspace.longName : 'Home'
              }
            };
            componentObj.component.push(newcomponentObj);
          } else {
            let sortedWorkspace = workspace.layout;
            if (workspace.layout[0].index) {
              sortedWorkspace = this.sortContent(workspace.layout);
            }
            sortedWorkspace.forEach((wItem) => {
              if (wItem.content && wItem.content.layout && wItem.content.layout.length > 0) {
                wItem.content.layout.forEach(element => {
                  const newcomponentObj = {
                    type: element.type,
                    name: element.shortName ? element.shortName : element.longName,
                    attributes: {
                      dashboard: wItem.shortName ? wItem.shortName : wItem.longName ? wItem.longName : 'Home'
                    }
                  };
                  if (newcomponentObj.type !== 'MarketingWidget'){
                    if (window.innerWidth < 767 && element?.layoutTypeId === 'a7bfbed7-4c26-4e5b-97ad-a9a7f8eb3185') {
                      componentObj.component.push(newcomponentObj);
                    }
                    else if (window.innerWidth > 767 && window.innerWidth < 1025 && element?.layoutTypeId === '1d1173e0-9d10-45d5-91e7-62b26796e657') {
                      componentObj.component.push(newcomponentObj);
                    }
                    else if (window.innerWidth > 1025 && element?.layoutTypeId === '63329290-d9c3-48f1-8567-bc36d3d723ab') {
                      componentObj.component.push(newcomponentObj);
                    }
                  }
                      });
              } else {
                const newcomponentObj = {
                  type: wItem.type,
                  name: wItem.shortName ? wItem.shortName : wItem.longName,
                  attributes: {
                    dashboard: workspace.shortName ? workspace.shortName : workspace.longName ? workspace.longName : 'Home'
                  }
                };
                if (newcomponentObj.type !== 'MarketingWidget'){
                  componentObj.component.push(newcomponentObj);    
                }
              }
            });
          }
        } else if (eventObj && eventObj.componentReplace) {
          componentObj.component.push(eventObj.component);
        } else {
          componentObj.component = [];
        }
      }
      componentObj.component = componentObj.component.sort((a, b) => {
        if (a.type.toLowerCase() < b.type.toLowerCase()) { return -1; }
        if (a.type.toLowerCase() > b.type.toLowerCase()) { return 1; }
        return 0;
      });
      return componentObj;
    }

  }
  sortContent(contentList) {
    // tslint:disable-next-line: only-arrow-functions
    return contentList.sort(function(a, b) { return (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0); });
  }

  /******************************
   * Event- specific functions
   ******************************/
  // user has clicked a 1x1 widget
  private widgetClickLinkFn(eventId, srcCtrl) {
    const defaultWidgetClickObj = merge(cloneDeep(this.defaultEventObj),
      { event: [{ eventInfo: { eventName: 'Click', eventAction: 'click', type: 'icon' }, attributes: { linkName: '' } }] });
    const widget = srcCtrl.data;
    if (!widget) {
      return defaultWidgetClickObj;
    }
    let eventObj: any = cloneDeep(defaultWidgetClickObj);
    if (this.analyticTags[widget.type + 'ClickLink']) {
      eventObj = cloneDeep(this.analyticTags[widget.type + 'ClickLink']);
    }

    // get widgetName, add as attribute
    if (widget.shortName) {
      eventObj.event.event[0].attributes.linkName = widget.shortName;
    }
    return eventObj;
  }

  // user has clicked or moved a widget
  private widgetClickMoveFn(event, srcCtrl, eventId) {
      this.eventCatalogSharedService.eventCatalogTracking('Home:'
      + (srcCtrl?.workspace?.longName + ':' + srcCtrl.data.shortName),
      eventId, null, srcCtrl.data.shortName === 'Apps' ? null : srcCtrl.id);
  }

  // All the apps*Fn functions do the same processing, just differing the the eventId
  // (which is the function name)
  // these are generated from the Apps page
  private appsClickFn(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }

  private appsClickFromDetailFn(eventId, srcCtrl) {
    return this.appsTrackFromDetail(eventId, srcCtrl);
  }

  private appsAddFn(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }
  private appsClickFnHeart(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }
  private appsAddToFvrt(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }
  private appsRelatedto(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }
  private appsOpenRelatedApp(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }
  private appsAddFromDetailFn(eventId, srcCtrl) {
    return this.appsTrackFromDetail(eventId, srcCtrl);
  }
  private appsRemoveFromDetailFn(eventId, srcCtrl) {
    return this.appsTrackFromDetail(eventId, srcCtrl);
  }
  private appsRequestAccessFromDetailFn(eventId, srcCtrl) {
    return this.appFromPsp(eventId, srcCtrl);
  }
  private appsFromPsp(eventId, srcCtrl) {
    return this.appFromPsp(eventId, srcCtrl);
  }
  private mrcmOptions(eventId, srcCtrl, obj) {
    return this.mrcmEventsFn(eventId, srcCtrl, obj);
  }
  private mrcmPagination(eventId, srcCtrl, obj) {
    return this.mrcmEventsFn(eventId, srcCtrl, obj);
  }
  private mrcmLinkClickCR(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl.data.shortName + ':Cust RO:' + obj.CustRO;
    return eventObj;
  }
  private mrcmLinkClickVN(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl.data.shortName + ':' + obj.CustRO + ':View Note';
    return eventObj;
  }
  private mrcmDownload(eventId, srcCtrl, obj) {
    return this.mrcmEventsFn(eventId, srcCtrl, obj);
  }
  private appsLearnMoreFn(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }
  private appsDragIndicator(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }
  private appsRemoveFrmFvrt(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }
  private appsRocketLaunch(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }
  private appsDeleteFromMyFav(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }
  private appsLearnMoreImageFn(eventId, srcCtrl) {
    return this.appsTrack(eventId, srcCtrl);
  }

  private appDetailsFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    const app = srcCtrl.shortName;
    eventObj.page.pageInfo.pageName += ':' + app;
    return eventObj;
  }

  private secondaryBannerNotificationIconFn(eventId, srcCtrl) {
    const msgId = (!srcCtrl.unreadNotificationsAvailable)
      ? 'secondaryBannerNotificationIconFn'
      : 'secondaryBannerNotificationUnread';
    const msgIdData = cloneDeep(this.analyticTags[msgId]);
    this.eventCatalogSharedService.eventCatalogTracking('Header:' + msgIdData.event.event[0].eventInfo.eventName, eventId);
    return msgIdData;
  }

  /*
   * rendering Apps page, we need to know whether in list or icon view
   * @param {type} eventId
   * @param {type} srcCtrl
   * @returns pageViewObject to be merged into digitalData
   */
  private appsPageFn(eventId, srcCtrl) {
    let appsView;
    if (localStorage && localStorage.getItem) {
      appsView = localStorage.getItem('MBF-apps-view');
    }
    if (!appsView) {
      appsView = 'list';
    }

    let pageViewObj: any = {};
    if (this.analyticTags[eventId]) {
      pageViewObj = cloneDeep(this.analyticTags[eventId]);
    }

    if (pageViewObj && pageViewObj.event && pageViewObj.event.page && pageViewObj.event.page.attributes) {
      merge(pageViewObj, { event: { page: { attributes: { sortBy: appsView } } } });
      pageViewObj = this.addAppsPageAttributes(srcCtrl, pageViewObj);
    }

    return pageViewObj;
  }

  /*
   * If User has set Category refinements on the Apps page, add to pageViewObj
   * @param {type} srcCtrl source controller
   * @param {type} pageViewObj
   * @returns pageViewObject containing App Category refinements
   */
  private addAppsPageAttributes(srcCtrl, pageViewObj) {
    const refinements = this.buildAppPageCategories(srcCtrl);
    try {
      merge(pageViewObj, { event: { page: { attributes: { facetRefinement: [] } } } });
      if (refinements.length > 0) {
        pageViewObj.event.page.attributes.facetRefinement = refinements;
      }
      else {
        delete pageViewObj.event.page.attributes.facetRefinement;
      }
    } catch (e) {
    }

    // add appSearch term if present
    if (srcCtrl && srcCtrl.appSearch && srcCtrl.appSearch.length > 0) {
      merge(pageViewObj, { event: { page: { pageInfo: { onsiteSearchTerm: srcCtrl.appSearch } } } });
    }
    return pageViewObj;
  }

  /*
   * on Apps page, build list of selected refinements
   * @param {type} srcCtrl
   * @returns {Array}
   */
  private buildAppPageCategories(srcCtrl) {
    let categories = [];
    const refinements = [];
    if (srcCtrl && srcCtrl.$parent && srcCtrl.$parent.categories) {
      categories = srcCtrl.$parent.categories;
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      if (category.on && category.name) {
        refinements.push({ refinementName: 'Category', refinementValue: category.name });
      }
    }
    return refinements;
  }

  /*
   * On the AppStore page, the event name refers to the application that
   * the user clicked on. This may come from the slide being displayed on the header
   * or the links or icon for the individual link.
   * @param {type} srcCtrl the controller where evenet
   * @returns {eventName|String}
   */
  private appsTrackEventName(srcCtrl) {
    let eventName;
    if (srcCtrl.app && srcCtrl.app.shortName) {
      eventName = srcCtrl.app.shortName;
    }
    else if (srcCtrl.slide && srcCtrl.slide.title) {
      eventName = srcCtrl.slide.title;
    }
    else {
      eventName = 'unknown';
    }
    return eventName;
  }

  /*
   * track click events from the AppList page.
   * These are all the same, differing only in eventName
   * @param {type} eventId
   * @param {type} srcCtrl
   * @returns eventObj that will be merged into digitalData object
   */
  private appsTrack(eventId, srcCtrl) {
    let eventObj: any = cloneDeep(this.analyticTags[eventId]);
    const eventName = this.appsTrackEventName(srcCtrl);
    const widgetId = srcCtrl.app.id;
    eventObj.event.event[0].eventInfo.eventName = eventName;
    if(eventId === 'appsOpenRelatedApp') {
      const relatedItem = srcCtrl.app.relatedItem;
      const eventAction = eventObj.event.event[0].eventInfo.eventAction;
      eventObj.event.event[0].eventInfo.eventAction = eventAction + ': ' + relatedItem;
    }
    eventObj = this.addAppsPageAttributes(srcCtrl, eventObj);
    // this.logentry('[analyticService]appsTrack returning eventObj', eventObj);
    this.eventCatalogSharedService.eventCatalogTracking('Applications:' + eventObj.event.event[0].eventInfo.eventName,
      eventId, null, widgetId);
    return eventObj;
  }

  /*
   * when tracking click events from the Apps Detail page, call appsTrack
   * then add app name to pageName
   * @param {type} eventId
   * @param {type} srcCtrl
   * @returns {mbf_analytics_L10.appsTrackFromDetail@call;appsTrack}
   */
  private appsTrackFromDetail(eventId, srcCtrl) {
    const eventObj = this.appsTrack(eventId, srcCtrl);
    if (eventObj.page && eventObj.page.pageInfo) {
      eventObj.page.pageInfo.pageName = ':' + srcCtrl.app.shortName;
    }
    return eventObj;
  }
  private appFromPsp(eventId, linkArray) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += linkArray[0];
    if (eventObj.page && eventObj.page.pageInfo) {
      eventObj.page.pageInfo.pageName = ':' + linkArray[linkArray.length - 1];
    }
    const action = linkArray[0]?.split(' ');
    this.eventCatalogSharedService.eventCatalogTracking('PSP:' + linkArray[1], eventId, action[0], linkArray[2]);
    return eventObj;
  }

  private mrcmEventsFn(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl.data.shortName + ':' + obj;
    return eventObj;
  }

  private carouselLearnMoreClickFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);

    eventObj.event.event[0].eventInfo.eventName = srcCtrl.ctrl.widget.longName + eventObj.event.event[0].eventInfo.eventName;
    eventObj.event.event[0].attributes.linkName = srcCtrl.slide.title;
    eventObj.event.event[0].attributes.url = srcCtrl.slide.url;
    return eventObj;
  }
  private carouselArrowClickFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl.ctrl.widget.longName + eventObj.event.event[0].eventInfo.eventName;
    return eventObj;
  }

  private carouselContentFn(eventId, controller) {
    // TODO right now there is only Featured widget that is a carousel
    // do we track all featured widgets, or filter them??
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    const widget = controller.ctrl.widget;
    // prepend widget name
    eventObj.event.event[0].eventInfo.eventName = widget.longName + eventObj.event.event[0].eventInfo.eventName;
    eventObj.event.event[0].eventInfo.type = widget.type;
    // get currently displayed item
    eventObj.event.event[0].attributes.linkName = controller.currentContent.title;
    eventObj.event.event[0].attributes.linkURL = controller.currentContent.url;
    return eventObj;
  }

  private widgetResizeFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    return eventObj;
  }


  /*
   * build the community where the user is when the event is triggered
   * @param {type} currentWorkspace
   * @returns {currentWorkspace.shortName|String}
   */
  private getCommunityNamePrefix(currentWorkspace) {
    const communityName = (currentWorkspace && currentWorkspace.shortName)
      ? currentWorkspace.shortName
      : 'unknown';

    // if community name is <userName>'s Workspace, then it's really MBF or MMF and will not show a page name prefix
    const pattern = /\'s Workspace/i;
    const communityNamePrefix = (pattern.test(communityName))
      ? ''
      : communityName + ':';

    return communityNamePrefix;
  }

  private addToFolderFn(eventId, srcObj) {

    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    const layoutToTransfer = srcObj.layoutToTransfer;
    const newParent = srcObj.newParent;
    const srcScope = srcObj.srcScope;

    const targetObj = this.getWidget(srcScope, newParent);
    if (targetObj.type === 'BasicFolder') {
      const widgetToTransfer = this.getWidget(srcScope, layoutToTransfer);
      eventObj.event.event[0].attributes.contentType = widgetToTransfer.type;
      eventObj.event.event[0].attributes.contentName = widgetToTransfer.shortName;
    }
    else {
      eventObj.action = this.noAction;
    }
    return eventObj;
  }

  private addLinkToFolderFn(eventId, srcObj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    const layoutToTransfer = srcObj.layoutToTransfer;
    const newParent = srcObj.newParent;
    const srcScope = srcObj.srcScope;

    const targetObj = this.getWidget(srcScope, newParent);
    if (targetObj.type === 'BasicFolder') {
      const widgetToTransfer = this.getWidget(srcScope, layoutToTransfer);
      eventObj.event.event[0].attributes.contentType = widgetToTransfer.type;
      eventObj.event.event[0].attributes.contentName = widgetToTransfer.shortName;
    }
    else {
      eventObj.action = this.noAction;
    }
    return eventObj;
  }

  private nondefaultWorkspaceFn(eventId, workspace) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    const communityNamePrefix = this.getCommunityNamePrefix(workspace);
    if (eventObj.page && eventObj.page.pageInfo) {
      eventObj.page.pageInfo.pageName = communityNamePrefix + 'Home';
      // set flag to replace pageName in buildPageObj
      eventObj.page.pageInfo.pageNameReplace = true;
    }
    this.eventCatalogSharedService.eventCatalogTracking('Header:MBF Home', eventId);
    return eventObj;
  }

  /*
   * Add the tutorial page to the pageName string
   * @param {type} eventId
   * @param {type} tutorialPage
   * @returns pageObj, to be merged into digitalData
   */
  private tutorialPageFn(eventId, tutorialPage) {
    const pageObj = cloneDeep(this.analyticTags[eventId]);
    pageObj.page.pageInfo.pageName += ':' + tutorialPage;
    return pageObj;
  }

  private tutorialSmallScreenPageFn(eventId) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    return eventObj;
  }

  private removeWidgetFn(eventId, widget) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + widget.data.longName;
    this.eventCatalogSharedService.eventCatalogTracking('Home:' + widget.workspaceShortName + ':' + widget.data.shortName,
     eventId, null, widget.data.id);
    return eventObj;
  }

  private marketingCampaignFn(eventId, srcCtrl, obj) {
    let pageName = this.router.routerState.root.snapshot.firstChild.data.analytics === undefined ?
      // tslint:disable-next-line: no-string-literal
      this.router.routerState.root.snapshot.firstChild.firstChild.data.analytics['pageName'] :
      // tslint:disable-next-line: no-string-literal
      this.router.routerState.root.snapshot.firstChild.data.analytics['pageName'];
    pageName = (pageName === 'Home') ? 'MBF Home' : pageName;
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = eventObj.event.event[0].eventInfo.eventName + obj.buttonUrl;
    eventObj.event.component[0].type = 'MarketingWidget';
    eventObj.event.component[0].name = obj.title;
    if (pageName  === 'MBF Home' ){
      Object.assign(eventObj.event.component[0], {
        attributes : {
          dashboard: 'MBF Home'
        }
      });
    }
    return eventObj;
  }

  private bannerCampaignFn(eventId, srcCtrl, obj) {
    let pageName = this.router.routerState.root.snapshot.firstChild.data.analytics === undefined ?
      // tslint:disable-next-line: no-string-literal
      this.router.routerState.root.snapshot.firstChild.firstChild.data.analytics['pageName'] :
      // tslint:disable-next-line: no-string-literal
      this.router.routerState.root.snapshot.firstChild.data.analytics['pageName'];
    pageName = (pageName === 'Home') ? 'MBF Home' : pageName;
    const componentObj: any = cloneDeep(this.analyticTags[eventId]);
    componentObj.event.component[0].type = 'MarketingWidget';
    componentObj.event.component[0].name = obj.title;
    if (pageName  === 'MBF Home' ){
      Object.assign(componentObj.event.component[0], {
        attributes : {
          dashboard: 'MBF Home'
        }
      });
    }
    return componentObj;
  }

  // Global Nav Bar Adobe Analytics
  private globalNavPrimaryFn(eventId, primaryLink) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].attributes.linkName = primaryLink.title;
    return eventObj;
  }

  private globalNavLogoFn(eventId, oemName) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].attributes.linkName = oemName;
    return eventObj;
  }

  private globalNavPlatformFn(eventId, oemPlatformName) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + oemPlatformName;
    eventObj.event.event[0].attributes.linkName = '';
    return eventObj;
  }

  private globalNavShopFn(eventId, title) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + title;
    eventObj.event.event[0].attributes.linkName = '';
    return eventObj;
  }

  private globalNavIceCubeTrayFn(eventId, iconTitle) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + iconTitle;
    eventObj.event.event[0].attributes.linkName = '';
    return eventObj;
  }

  private globalNavAccountFn(eventId, iconTitle) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + iconTitle;
    eventObj.event.event[0].attributes.linkName = '';
    return eventObj;
  }

  private globalNavShopDropDownFn(eventId, menuItemTitle) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].attributes.linkName = menuItemTitle;
    return eventObj;
  }

  private globalNavTrayDropDownExitLinkFn(eventId, trayDropDownArray) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + trayDropDownArray[0];
    eventObj.event.event[0].attributes.linkName = trayDropDownArray[1];
    return eventObj;
  }

  private globalNavSecondaryFn(eventId, secondaryLink) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + secondaryLink.title;
    return eventObj;
  }



  private globalNavSubmenuFn(eventId, subMenuArray) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    const sLinkTitle = subMenuArray[0].title;
    const subMenuTitle = subMenuArray[1].title;
    eventObj.event.event[0].eventInfo.eventName += ':' + sLinkTitle;
    eventObj.event.event[0].attributes.linkName = subMenuTitle;
    return eventObj;
  }

  private globalNavSubmenuCloseFn(eventId, secondaryLink) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + secondaryLink.title;
    return eventObj;
  }

  private secondaryBannerApplicationFn(eventId, secondaryBannerName) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + secondaryBannerName;
    this.eventCatalogSharedService.eventCatalogTracking(
      eventObj.event.event[0].eventInfo.eventName.substring(eventObj.event.event[0].eventInfo.eventName.indexOf('Header')),
      eventId);
    return eventObj;
  }

  private secondaryBannerMobileClickFn(eventId, secondaryBannerName) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + secondaryBannerName;
    return eventObj;
  }

  private secondaryBannerApiStoreFn(eventId, secondaryBannerName) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].attributes.linkName = secondaryBannerName;
    return eventObj;
  }

  private secondaryBannerIconsFn(eventId, secondaryBannerName) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + secondaryBannerName;
    return eventObj;
  }

  private secondaryNavDropDownExitFn(eventId, subMenuArray) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    const parentBannerName = subMenuArray[0];
    const childBannerName = subMenuArray[1];
    eventObj.event.event[0].eventInfo.eventName += ':' + parentBannerName;
    eventObj.event.event[0].attributes.linkName = childBannerName;
    return eventObj;
  }

  private secondaryNavDropDownClickFn(eventId, subMenuArray) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    const parentBannerName = subMenuArray[0];
    let childBannerName = subMenuArray[1];
    if (childBannerName.endsWith('Workspace')) {
      childBannerName = 'User\'s Workspace';
    }
    eventObj.event.event[0].eventInfo.eventName += ':' + parentBannerName + ':' + childBannerName;
    return eventObj;
  }

  private globalNavTrayDropDownNonExitLinkFn(eventId, trayDropDownArray) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + trayDropDownArray[0] + ':' + trayDropDownArray[1];
    eventObj.event.event[0].attributes.linkName = '';
    return eventObj;
  }

  private globalNavAccountDropDownFn(eventId, title) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + title;
    eventObj.event.event[0].attributes.linkName = '';
    this.eventCatalogSharedService.eventCatalogTracking('Header:' + eventObj.event.event[0].eventInfo.eventName, eventId);
    return eventObj;
  }

  private footerNavFn(eventId, linkArray) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    const linkCategoryTitle = linkArray[0].title;
    let linkTitle = linkArray[1].title;
    const linkUrl = linkArray[1].url;

    // Scrub PII out of the Workspace Title's
    if (this.isMBFWorkspace(linkTitle)) {
      linkTitle = 'User\'s Workspace';
    }

    if ((linkUrl && linkUrl.indexOf('#/Platform') > -1) || linkArray[1].clickType) {
      // Not an Exit link
      eventObj.event.event[0].eventInfo.eventName += ':' + linkCategoryTitle + ':' + linkTitle;
      eventObj.event.event[0].eventInfo.eventAction = 'Click';
    } else {
      // Exit Link
      eventObj.event.event[0].eventInfo.eventName += ':' + linkCategoryTitle;
      eventObj.event.event[0].attributes.linkName = linkTitle;
    }
    return eventObj;
  }

  private footerBrandFn(eventId, brandLink) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].attributes.linkName = brandLink.title;
    return eventObj;
  }
  /* Helper function to get the widget triggering an event*/
  private footerSocialMediaFn(eventId, socialMediaLink) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].attributes.linkName = socialMediaLink.title;
    return eventObj;
  }

  /*
   * @param {type} srcScope
   * @param {type} widgetId
   * @returns {mbf_analytics_L52.this.getWidget.mbf_analyticsAnonym$7}
   */
  private getWidget(srcScope, widgetId) {
    if (srcScope.currentWorkspace.id === widgetId) {
      return srcScope.currentWorkspace;
    }
    // we're passed the widgetId, get list of widgets in the layout,
    // loop through and find the one that matches
    const workspaceLayout = srcScope.currentWorkspace.layout;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < workspaceLayout.length; i++) {
      const curr = workspaceLayout[i];
      if (curr.id === widgetId || curr.layoutId === widgetId) {
        return curr;
      }
    }
    return {};
  }

  private logentry(logentry, evnt) {
    if (this.log) {
    }
  }

  private isMBFWorkspace(workspace) {
    let isMBFWorkspace = false;
    const pattern = /\'s Workspace/i;
    if (typeof workspace === 'string') {
      isMBFWorkspace = (pattern.test(workspace));
    } else if (typeof workspace === 'object') {
      isMBFWorkspace = (pattern.test(workspace.shortName));
    }
    return isMBFWorkspace;
  }

  /*
  * rendering Apps page, we need to know whether in list or icon view
  * @param {type} eventId
  * @param {type} srcCtrl
  * @returns pageViewObject to be merged into digitalData
  */
  private appsPageRefineFn(eventId, srcCtrl) {
    let appsView;
    if (localStorage && localStorage.getItem) {
      appsView = localStorage.getItem('MBF-apps-view');
    }
    if (!appsView) {
      appsView = 'list';
    }


    let pageViewObj: any = {};
    if (this.analyticTags[eventId]) {
      pageViewObj = cloneDeep(this.analyticTags[eventId]);
    }

    if (pageViewObj && pageViewObj.event && pageViewObj.event.page && pageViewObj.event.page.attributes) {
      merge(pageViewObj, { event: { page: { attributes: { sortBy: appsView } } } });
      pageViewObj = this.addAppsPageRefineAttributes(srcCtrl, pageViewObj);
    }

    return pageViewObj;
  }

  /*
  * If User has set Product Group refinements on the Apps page, add to pageViewObj
  * @param {type} srcCtrl source controller
  * @param {type} pageViewObj
  * @returns pageViewObject containing App Category refinements
  */
  private addAppsPageRefineAttributes(srcCtrl, pageViewObj) {
    const refinements = this.buildAppPageRefineCategories(srcCtrl);
    try {
      merge(pageViewObj, { event: { page: { attributes: { facetRefinement: [] } } } });
      if (refinements.length > 0) {
        pageViewObj.event.page.attributes.facetRefinement = refinements;
      }
      else {
        delete pageViewObj.event.page.attributes.facetRefinement;
      }
    } catch (e) {
  
    }

    // add appSearch term if present
    if (srcCtrl && srcCtrl.appSearch && srcCtrl.appSearch.length > 0) {
      merge(pageViewObj, { event: { page: { pageInfo: { onsiteSearchTerm: srcCtrl.appSearch } } } });
    }
    return pageViewObj;
  }

  /*
  * on Apps page, build list of selected refinements
  * @param {type} srcCtrl
  * @returns {Array}
  */
  private buildAppPageRefineCategories(srcCtrl) {
    let categories = [];
    const refinements = [];
    if (srcCtrl && srcCtrl.$parent && srcCtrl.$parent.categories) {
      categories = srcCtrl.$parent.categories;
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      if (category.on && category.name) {
        refinements.push({ refinementName: 'Product Group', refinementValue: category.name });
      }
    }
    return refinements;
  }

  /*
  * rendering Apps page, we need to know whether in list or icon view
  * @param {type} eventId
  * @param {type} srcCtrl
  * @returns pageViewObject to be merged into digitalData
  */
  private appsPageDisplayFn(eventId, srcCtrl) {
    let appsView;
    if (localStorage && localStorage.getItem) {
      appsView = localStorage.getItem('MBF-apps-view');
    }
    if (!appsView) {
      appsView = 'list';
    }

    let pageViewObj: any = {};
    if (this.analyticTags[eventId]) {
      pageViewObj = cloneDeep(this.analyticTags[eventId]);
    }

    if (pageViewObj && pageViewObj.event && pageViewObj.event.page && pageViewObj.event.page.attributes) {
      merge(pageViewObj, { event: { page: { attributes: { sortBy: appsView } } } });
      pageViewObj = this.addAppsPageDisplayAttributes(srcCtrl, pageViewObj);
    }

    return pageViewObj;
  }

  /*
  * If User has set Display on the Apps page, add to pageViewObj
  * @param {type} srcCtrl source controller
  * @param {type} pageViewObj
  * @returns pageViewObject containing App Category refinements
  */
  private addAppsPageDisplayAttributes(srcCtrl, pageViewObj) {
    const display = srcCtrl;
    try {
      merge(pageViewObj, { event: { page: { attributes: { sortBy: [] } } } });

      // Pete Nelson - the if statement incorrectly had an or (||) condition between the clauses
      if (display != null && display !== '') {
        pageViewObj.event.page.attributes.sortBy = display;
      }
      else {
        delete pageViewObj.event.page.attributes.sortBy;
      }
    } catch (e) {
    }

    return pageViewObj;
  }


  /*
  * All the Dashboard*Fn functions do the same processing, just differing the the eventId
  * (which is the function name)
  * these are generated from the Dock page */
  private expandDashboard(eventId, srcCtrl) {
    return this.dashboardTrack(eventId, srcCtrl);
  }

  private collapseDashboard(eventId, srcCtrl) {
    return this.dashboardTrack(eventId, srcCtrl);
  }

  private moveUpDashboard(eventId, srcCtrl) {
    return this.dashboardTrack(eventId, srcCtrl);
  }

  private moveDownDashboard(eventId, srcCtrl) {
    return this.dashboardTrack(eventId, srcCtrl);
  }

  private removeDashboard(eventId, srcCtrl) {
    return this.dashboardTrack(eventId, srcCtrl);
  }

  /*
   * On the Dock page, the event name refers to the Dashboard that
   * the user clicked on. This may come from the slide being displayed on the header
   * or the links or icon for the individual link.
   * @param {type} srcCtrl the controller where evenet
   * @returns {eventName|String}
   */
  private dashboardTrackEventName(srcCtrl) {
    let eventName;
    if (srcCtrl) {
      eventName = srcCtrl.shortName ? srcCtrl.shortName : srcCtrl.longName;
    } else {
      eventName = 'unknown';
    }
    return eventName;
  }

  /*
   * track click events from the Dock page.
   * These are all the same, differing only in eventName
   * @param {type} eventId
   * @param {type} srcCtrl
   * @returns eventObj that will be merged into digitalData object
   */
  private dashboardTrack(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    const eventName = this.dashboardTrackEventName(srcCtrl);
    eventObj.event.event[0].eventInfo.eventName += ':' + eventName;
    if (eventId === 'collapseDashboard' || eventId === 'expandDashboard' || eventId === 'moveDownDashboard'
      || eventId === 'moveUpDashboard' || eventId === 'removeDashboard') {
      if (srcCtrl.shortName === 'My Favorites') {
        srcCtrl.id = null;
      }
      this.eventCatalogSharedService.eventCatalogTracking('Home:' + srcCtrl.shortName, eventId, null, srcCtrl.id);
    } else {
      this.eventCatalogSharedService.eventCatalogTracking('Home:' + srcCtrl.shortName, eventId, null, srcCtrl.app.id);
    }
    return eventObj;
  }

  private linkGroupTabSelectFn(eventId, srcCtrl, tabTitle) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl.workspace.shortName + ':' + srcCtrl.data.longName;
    eventObj.event.event[0].eventInfo.eventData = tabTitle;
    this.eventCatalogSharedService.eventCatalogTracking('Home:' + eventObj.event.event[0].eventInfo.eventName,
      eventId, eventObj.event.event[0].eventInfo.eventData);
    return eventObj;
  }

  private linkGroupLinkSelectFn(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = obj[0] + ':' + obj[1] + ':' + obj[2];
    eventObj.event.event[0].attributes.linkURL = obj[3];
    return eventObj;
  }

  private newsFeedNavigationFn(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = obj[0] + ':' + obj[1];

    return eventObj;
  }

  private newsFeedArticleExpandFn(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = obj;
    const widgetId = srcCtrl.id;
    this.eventCatalogSharedService.eventCatalogTracking('Home:' + srcCtrl.data.shortName, eventId);
    return eventObj;
  }

  private newsFeedArticleCollapseFn(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = obj;
    const widgetId = srcCtrl.id;
    this.eventCatalogSharedService.eventCatalogTracking('Home:' + srcCtrl.data.shortName, eventId);
    return eventObj;
  }

  private contactListTabSelectFn(eventId, srcCtrl, tabTitle) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl.workspace.shortName + ':' + srcCtrl.data.shortName;
    eventObj.event.event[0].eventInfo.eventData = tabTitle;
    this.eventCatalogSharedService.eventCatalogTracking('Home:' + eventObj.event.event[0].eventInfo.eventName,
      eventId, eventObj.event.event[0].eventInfo.eventData);
    return eventObj;
  }

  private contactListGrpEmailSelectFn(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = obj[0];
    eventObj.event.event[0].attributes.emailId = obj[1];
    return eventObj;
  }

  private calendarLinkSelectFn(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = obj[0];
    eventObj.event.event[0].attributes.linkURL = obj[1];
    return eventObj;
  }

  private calendarDateFn(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl.data.shortName;
    eventObj.event.event[0].attributes.date = obj;
    const widgetId = srcCtrl.app.id;
    if (srcCtrl.data.content !== undefined) {
      this.eventCatalogSharedService.eventCatalogTracking('Home:' + srcCtrl.data.shortName, eventId);
    }
    return eventObj;
  }

  private userRoleRequestSkip(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }

  private userRoleSurveySubmit(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }
  private userRoleSurveyOptOut(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }

  private backToTopAnalyticFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }

  private repairHistory(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'Repair History';
    return eventObj;
  }

  private airworthiness(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'Service Bulletins';
    return eventObj;
  }

  private serviceBulletins(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'Service Bulletins' + ':' + obj;
    return eventObj;
  }

  private aogPart(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'Open AOG Part Orders';
    return eventObj;
  }

  private appsClickFromSearchHeader(eventId, srcCtrl) {
    this.eventCatalogSharedService.eventCatalogTracking('Header:Search:'+srcCtrl?.app_shortname, eventId, '',srcCtrl?.wid);
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + srcCtrl.edescription;
    return eventObj;

  }

  private categoryClickFromFAQs(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + srcCtrl;
    return eventObj;
  }

  private clickFromPSPDocument(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + srcCtrl;
    return eventObj;
  }

  private clickFromClafSupportPage(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + srcCtrl;
    return eventObj;
  }

  private clickFromPSPRelatedSoftware(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + srcCtrl;
    return eventObj;
  }

  private clickFromPSPReleaseInfo(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + srcCtrl;
    return eventObj;
  }

  private clickFromPSPLegalInfo(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + srcCtrl;
    return eventObj;
  }

  private tabClickFromPSP(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + srcCtrl;
    return eventObj;
  }

  private kpiListWidgetTabSelectFn(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl.currentOrderTab.apiValue;
    this.eventCatalogSharedService.eventCatalogTracking('Home:' + srcCtrl.workspace.shortName + ':PurchaseOrder',
        eventId, srcCtrl.currentOrderTab.title);
    return eventObj;
  }

  private ViewAllOrdersClickFn(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'View all orders';
    this.eventCatalogSharedService.eventCatalogTracking('Home:' + srcCtrl.workspace.shortName + ':PurchaseOrder',
        eventId, srcCtrl.currentOrderTab.viewAllLabel);
    return eventObj;
  }

  private orderLinkClickFnOnHold(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'OnHold:' + srcCtrl.purchaseOrderNumber;
    eventObj.event.event[0].attributes.linkURL = srcCtrl.kpiListUrl;
    return eventObj;
  }

  private orderLinkClickFnPastDue(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'pastDue:' + srcCtrl.purchaseOrderNumber;
    eventObj.event.event[0].attributes.linkURL = srcCtrl.kpiListUrl;
    return eventObj;
  }

  private orderLinkClickFnShipped = function(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'shipped:' + srcCtrl.purchaseOrderNumber;
    eventObj.event.event[0].attributes.linkURL = srcCtrl.kpiListUrl;
    return eventObj;
  };

  private takeQuickSurveyClickFn(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'Take a brief survey';
    return eventObj;
  }

  private fockits(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'FOC Kits';
    return eventObj;
  }
  private focserviceBulletins(eventId, srcCtrl, obj) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'FOC Kits' + ':' + obj;
    return eventObj;
  }
  private focSBLink(eventId, srcCtrl, obj) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'FOC Kits' + ':' + srcCtrl.displayList.msnNumber + ':' + obj;
    return eventObj;
  }

  private addLinkWidget(eventId, srcCtrl, obj) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    if (srcCtrl && srcCtrl.workspace) {
      eventObj.event.event[0].eventInfo.eventName = srcCtrl.workspace.shortName + ':' + obj;
    } else {
      eventObj.event.event[0].eventInfo.eventName = eventObj.event.event[0].eventInfo.eventName + ':' + obj;
    }
    return eventObj;
  }

  private archiveFolder(eventId, srcCtrl) {
    return this.commonFolderFun(eventId, srcCtrl);
  }
  private sendFolder(eventId, srcCtrl) {
    return this.commonFolderFun(eventId, srcCtrl);
  }
  private addFolder(eventId, srcCtrl, obj) {
    return this.commonFolderFun(eventId, srcCtrl, obj);
  }
  private removeFolder(eventId, srcCtrl) {
    return this.commonFolderFun(eventId, srcCtrl);
  }
  commonFolderFun(eventId, srcCtrl, obj?) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    if (srcCtrl.workspace) {
      eventObj.event.event[0].eventInfo.eventName = srcCtrl.workspace.shortName + ':' + srcCtrl.data.shortName;
    } else {
      eventObj.event.event[0].eventInfo.eventName += obj ? (':' + obj) : '';
    }
    if (eventId === 'removeFolder'){
        obj = 'remove';
    }
    const data = {
      eventName: 'My Favorites' + ': ' + obj,
      eventAction: eventId,
      widgetId: null
    };
    this.eventCatalogSharedService.eventCatalogTracking('Home:My Favorites:' + obj, eventId);
    return eventObj;
  }
  private openLinkSearchResultCardFn(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'Open In: ' + srcCtrl[0];
    return this.buildAnalyticTagsForSearchPage(eventObj, srcCtrl);

  }
  private openLinkSearchAppCardFn(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    const title = srcCtrl.identifier.replace(/<mark>/g, '').replace(/<[/]mark>/g, '');
    eventObj.event.event[0].eventInfo.eventName = 'Open:' + srcCtrl.category + ':' + title;
    this.eventCatalogSharedService.eventCatalogTracking('Search:Search:' + srcCtrl.app_shortname, eventId, null, srcCtrl.wid);
    return eventObj;
  }
  private ViewMoreLinkSearchAppCardFn(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    const title = srcCtrl.identifier.replace(/<mark>/g, '').replace(/<[/]mark>/g, '');
    eventObj.event.event[0].eventInfo.eventName = 'Learn More:' + srcCtrl.category + ':' + title;
    this.eventCatalogSharedService.eventCatalogTracking('Search:Search:' + srcCtrl.app_shortname,
    eventId, null, srcCtrl.wid);
    return eventObj;
  }
  private viewAllMessagesFn(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'View All Messages:' + srcCtrl[0];
    return eventObj;
  }
  private expandSearchResultCardFn(eventId, srcCtrl, obj?) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = obj + ':' + srcCtrl[0];
    return this.buildAnalyticTagsForSearchPage(eventObj, srcCtrl);
}
  private openInDialogFn(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl[0].title;
    if (srcCtrl[0].appname && srcCtrl[0].wid) {
      this.eventCatalogSharedService.eventCatalogTracking('Search:Search:' + srcCtrl[0].appname, eventId, null, srcCtrl[0].wid);
    }

    return this.buildAnalyticTagsForSearchPage(eventObj, srcCtrl);

  }
  private saveLinkFn(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'Save Link:' + srcCtrl[0];
    return this.buildAnalyticTagsForSearchPage(eventObj, srcCtrl);
  }
  private relatedSearchResultsLinkFn(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'Related Search Results : ' + srcCtrl[0];
    return this.buildAnalyticTagsForSearchPage(eventObj, srcCtrl);
  }

  /* pia widget tags */
  private piaWidgetSearch(eventId, srcCtrl, results) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    if (results) {
      eventObj.event.page.pageInfo.onsiteSearchTerm = results.resultFor;
      eventObj.event.page.attributes.facetRefinement = [{ refinementValue: results.filterVal }];
      eventObj.event.page.pageInfo.onsiteSearchResults = results.totalParts;
    }
    if (srcCtrl.searchResults.filterVal === undefined  || srcCtrl.searchResults.filterVal === null){
      this.eventCatalogSharedService.eventCatalogTracking('Home:' + 'PIE', eventId,
      'Search:' + srcCtrl.searchResults.resultFor + ' Mod:' + '');
      return eventObj;
    } else {
      this.eventCatalogSharedService.eventCatalogTracking('Home:' + 'PIE', eventId,
      'Search:' + srcCtrl.searchResults.resultFor + ' Mod:' + srcCtrl.searchResults.filterVal);
      return eventObj;
    }
  }
  private pieWidgetEffecSearch(eventId, srcCtrl, results) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    if (results) {
      eventObj.event.page.pageInfo.onsiteSearchTerm = results.resultFor;
      eventObj.event.page.attributes.facetRefinement =
        [{
          refinementName: results.filterName,
          refinementValue: results.filterVal
        }];
      eventObj.event.page.pageInfo.onsiteSearchResults = results.totalResults;
    }
    return eventObj;
  }
  private piaWidgetPaginate(eventId, srcCtrl, query) {
    return this.piaWidgetSearch(eventId, srcCtrl, query);
  }
  private piaWidgetReset(eventId, srcCtrl) {
    return this.piaWidgetSearch(eventId, srcCtrl, null);
  }
  private piaWidgetSearchInvalid(eventId, srcCtrl, partNumber) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    if (partNumber) {
      eventObj.event.page.pageInfo.onsiteSearchTerm = partNumber;
    }
    return eventObj;
  }
  private piaWidgetDownload(eventId, srcCtrl, query) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    if (query) {
      eventObj.event.event[0].attributes.linkURL = query.partNumber + '.csv';
    }
    return eventObj;
  }
  private piaWidgetDetails(eventId, srcCtrl, partData) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    if (partData) {
      eventObj.event.event[0].eventInfo.eventName = 'Details:' + partData.partNumberA;
    }
    return eventObj;
  }
  private piaWidgetDetailLink(eventId, srcCtrl, linkName) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    if (linkName) {
      eventObj.event.event[0].eventInfo.eventName = linkName;
    }
    return eventObj;
  }
  private piaWidgetNoResLink(eventId, srcCtrl, linkName) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    if (linkName) {
      eventObj.event.event[0].eventInfo.eventName = linkName;
    }
    return eventObj;
  }

  /*search phase1 tags begin
  **/
  /*
    * tracks the eventName, eventAction and type
    *  when the user clicks on save
    */

  private saveSearch(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'Save Search:' + srcCtrl[0];
    return this.buildAnalyticTagsForSearchPage(eventObj, srcCtrl);
  }

  /*
  *
  * @returns eventObj which tracks the eventName, eventAction and type
  * when the user clicks on addSearch
  */

  private addSearch(eventId, srcCtrl) {
    const eventObj = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = 'Save Search:' + srcCtrl[0];
    return this.buildAnalyticTagsForSearchPage(eventObj, srcCtrl);
  }

  /*
 *
 * @param eventId is the name of the event
 * @param item is the object passed
 * @returns pageViewObj which contains the facetRefinement
 */
  private expandedSearch(eventId, item) {
    if (item[0].length > 0) {
      const refinements = this.buildExpandedSearchRefineCategories(item);
      let pageViewObj: any = {};
      if (this.analyticTags[eventId]) {
        pageViewObj = cloneDeep(this.analyticTags[eventId]);
      }
      merge(pageViewObj, { event: { page: { attributes: { facetRefinement: [] } } } });
      try {
        if (refinements.length > 0) {
          pageViewObj.event.page.attributes.facetRefinement = refinements;
        }
      }
      catch (e) {

      }
      this.eventCatalogSharedService.eventCatalogTracking('Search:Search', eventId, pageViewObj);
      return pageViewObj;
    }
    else {
      return '';
    }
  }

  /*
 *
 * @param eventId is the name of the event
 * @param item is the object passed
 *
 */
  private expandedSearchBox(eventId, item) {
    if (item) {
      this.eventCatalogSharedService.eventCatalogTracking('Search:Search', 'expandedSearch', item);
    }
  }

    /*
 *
 * @param eventId is the name of the event
 * @param item is the object passed
 *
 */
    private searchReset(eventId) {
      this.eventCatalogSharedService.eventCatalogTracking('Search:Search', 'SearchReset');
    }



  /*
   *
   * @param filter
   * @returns refinements with category and filter selected by the user
   */
  private buildExpandedSearchRefineCategories(filter) {
    const categories = [];
    const refinements = [];
    if (filter[0][0]) {
      refinements.push({ refinementName: 'Filter by', refinementValue: filter[0][0] });
      refinements.push({ refinementName: 'Filter by', refinementValue: 'Category' });
    }


    return refinements;
  }

  /**
   *
   * @param eventId holds the eventName, here the event is change of category in the global search header
   * @param item holds the changed category
   * @returns pageViewObj
   */
  private headerSearchCatChange(eventId, item) {
    const refinements = this.buildHeaderCategorySearchRefineCategories(item);
    let pageViewObj: any = {};
    if (this.analyticTags[eventId]) {
      pageViewObj = cloneDeep(this.analyticTags[eventId]);
    }
    merge(pageViewObj, { event: { page: { attributes: { facetRefinement: [] } } } });
    try {
      if (refinements.length > 0) {
        pageViewObj.event.page.attributes.facetRefinement = refinements;
      }
    }
    catch (e) {
    }
    return pageViewObj;
  }

  /*
   *
   * @param filter holds category
   * @returns the formatted refinement
   */
  private buildHeaderCategorySearchRefineCategories(filter) {
    const categories = [];
    const refinements = [];
    refinements.push({ refinementName: 'Category (Search Bar)', refinementValue: filter });
    refinements.push({ refinementName: 'Category', refinementValue: filter });
    if (filter) {
      return refinements;
    }
  }

  /*
  *
  * @param filter holds category when not selected from search bar
  * @returns the formatted refinement
  */
  private buildCategorySearchRefineCategories(filter, eventId?: string) {
    const categories = [];
    const refinements = [];
    if(eventId){
      refinements.push({ refinementName: 'Category (Expanded Search Bar)', refinementValue: filter });
    } else{
      refinements.push({ refinementName: 'Category', refinementValue: filter });
    }
    if (filter) {
      return refinements;
    }
  }

  private buildFilterByRefinement(filter, refinementName ?: any) {
    const categories = [];
    const refinements = [];
    refinements.push({ refinementName: refinementName, refinementValue: filter });
    if (filter) {
      return refinements;
    }
  }

  /**
   *
   * @param eventId gotoSearchPage tracks the searchText typed and the text selected from the typeaheads
   * @param item contains the searchText typed and text selected
   * @returns pageView
   */
  private gotoSearchPageTrack(eventId, item) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    if (item) {
      eventObj.event.page.pageInfo.onsiteSearchTerm = item[1];
      eventObj.event.page.attributes.searchTermTyped = item[0];
      merge(eventObj, { event: { page: { attributes: { facetRefinement: [] } } } });
      eventObj.event.page.attributes.facetRefinement = [{ refinementName:'Category',refinementValue: item[2] }];

    }

    try {
      let digitalData = cloneDeep(this.digitalDataBase);
      digitalData = merge(digitalData, eventObj.event);

    } catch (e) { }
    return eventObj;

  }
  /**
   *
   * @param eventId gotoSearchPageTrackWithoutTypeAhead when the user types the data but does not select from typeahead
   * @param item contains the search term typed
   * @returns digitalData
   */

  private gotoSearchPageTrackWithoutTypeAhead(eventId, item) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    if (item) {
      eventObj.event.page.pageInfo.onsiteSearchTerm = item[0];
      if (item[1]) {
        if (item[1] !== '' || item[1] !== undefined) {
          eventObj.event.page.pageInfo.onsiteSearchResults = item[1];
        }
      }
      else {
        eventObj.event.page.pageInfo.onsiteSearchResults = '0';
      }
      merge(eventObj, { event: { page: { attributes: { facetRefinement: [] } } } });
      eventObj.event.page.attributes.facetRefinement = [{ refinementName:'Category',refinementValue: item[2] }];

    }

    try {
      // build digitalData object by copying base data and merging in other event- related objects
      let digitalData = cloneDeep(this.digitalDataBase);
      digitalData = merge(digitalData, eventObj.event);
    } catch (e) {
    }
    return eventObj;

  }

  /**
   *
   * @param eventId gotoSearchPageFromHeader when the user types the data but does not select from typeahead
   * @param item contains the search term typed
   */

  private gotoSearchPageFromHeader(eventId, item) {
    
      let searchText = item?.searchText;
      let searchCat = item?.searchCategory;
      if(searchText !== undefined && searchCat !== undefined){
        this.eventCatalogSharedService.eventCatalogTracking('Header:Search', 'gotoSearchPageFromHeader', 'Terms:'+searchText+' Mod: Cat:'+searchCat);
      }
  }

  /*
   *
   * @param eventId holds the eventId while selecting the category from the searchresultspage
   * @param item holds the category
   * @returns the pageViewObj
   */
  private selectFilter(eventId, item) {
    const refinements = this.buildCategorySearchRefineCategories(item);
    let pageViewObj: any = {};
    if (this.analyticTags[eventId]) {
      pageViewObj = cloneDeep(this.analyticTags[eventId]);
    }
    merge(pageViewObj, { event: { page: { attributes: { facetRefinement: [] } } } });
    try {
      if (refinements.length > 0) {
        pageViewObj.event.page.attributes.facetRefinement = refinements;
      }
    }
    catch (e) {
     
    }
    this.eventCatalogSharedService.eventCatalogTracking('Search:Search', eventId,
      'Terms: : Mod: Cat: ' + pageViewObj.event.page.attributes.facetRefinement[0].refinementValue);
    return pageViewObj;
  }

  private selectFilterBy(eventId, refinements: any, results: any) {
    let pageViewObj: any = {};
    if (this.analyticTags[eventId]) {
      pageViewObj = cloneDeep(this.analyticTags[eventId]);
    }
    merge(pageViewObj, {
      event: {
        page: {
          pageInfo: {
            "onsiteSearchTerm": "",
            "onsiteSearchResults": ""
          },
          attributes: { facetRefinement: [], resultsInView: '25', viewMore: '1 - 25', aircraftMSN: '' }
        }
      }
    });
    try {
      if (refinements.length > 0) {
        pageViewObj.event.page.attributes.facetRefinement = refinements;
      }
      if(results){
        pageViewObj.event.page.pageInfo.onsiteSearchTerm = results.onsiteSearchTerm;
        pageViewObj.event.page.pageInfo.onsiteSearchResults = results.searchResults;
        pageViewObj.event.page.attributes.resultsInView = results.resultsInView;
        pageViewObj.event.page.attributes.viewMore = results.viewMore;
        pageViewObj.event.page.attributes.aircraftMSN = results.aircraftMSN;
      }
      
    }
    catch (e) {
    
    }
    
    return pageViewObj;
  }

  private selectCategoriesForExpandedSearchBox(eventId, item) {
    const refinements = this.buildCategorySearchRefineCategories(item,eventId);
    let pageViewObj: any = {};
    if (this.analyticTags[eventId]) {
      pageViewObj = cloneDeep(this.analyticTags[eventId]);
    }
    merge(pageViewObj, { event: { page: { attributes: { facetRefinement: [] } } } });
    try {
      if (refinements.length > 0) {
        pageViewObj.event.page.attributes.facetRefinement = refinements;
      }
    }
    catch (e) {
    }
    
    return pageViewObj;
  }
  
  private expandFAQ(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + srcCtrl.id;
    return eventObj;
  }
  private browserIdentificationContinue(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }
  private eolIENotification(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }
  private eolEdgeNotification(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }

  private hotPartsSingleDeleteFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + srcCtrl;
    return eventObj;
  }

  private hotPartsDeleteListFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }

  private hotPartsDownloadCSVFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }

  private hotPartsFilterStatusByFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }

  private hotPartsPurchaseOrderNumberFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }

  private hotPartsQuickSearchFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    this.eventCatalogSharedService.eventCatalogTracking('Home:MBD:HotPartsList', eventId, 'Search:' + srcCtrl);
    return eventObj;
  }

  private hotPartsQuickAddFn(eventId, srcCtrl) {
    this.eventCatalogSharedService.eventCatalogTracking('Home:MBD:HotPartsList', eventId, 'Add Part:' + srcCtrl);
  }

  private hotPartsEditPartFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }

  private hotPartsGetListStatusFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }

  private hotPartsUploadNewListFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    return eventObj;
  }

  private hotPartsBrowseFn(eventId, srcCtrl, obj) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    eventObj.event.event[0].eventInfo.attribute = (obj ? 'File contains first row as heading' : 'File does not contains first row as heading');
    return eventObj;
  }

  private hotPartsTabGroup(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName += ':' + srcCtrl;
    this.eventCatalogSharedService.eventCatalogTracking('Home:MBD:HotPartsList', eventId, srcCtrl);
    return eventObj;
  }

  private hotPartsPSPfn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventName = srcCtrl;
    this.eventCatalogSharedService.eventCatalogTracking('Home:MBD:' + srcCtrl, eventId);
    return eventObj;
  }

  private hotPartsUploadedMessageFn(eventId, srcCtrl) {
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    eventObj.event.event[0].eventInfo.eventAction = srcCtrl;
    return eventObj;
  }

  private trainingHubOpenFn(eventId, srcCtrl) {
    return this.trainingHubComponentObj(eventId, srcCtrl);
  }

  private trainingHubLearnMoreFn(eventId, srcCtrl) {
    return this.trainingHubComponentObj(eventId, srcCtrl);
  }

  private trainingHubRequestAccessFn(eventId, srcCtrl) {
    return this.trainingHubComponentObj(eventId, srcCtrl);
  }

  private trainingHubImageClickRequestAccess(eventId, srcCtrl) {
    return this.trainingHubComponentObj(eventId, srcCtrl);
  }

  private trainingHubImageClickOpen(eventId, srcCtrl) {
    return this.trainingHubComponentObj(eventId, srcCtrl);
  }

  private trainingHubApplicationCardFn(eventId, srcCtrl) {
    return this.trainingHubComponentObj(eventId, srcCtrl);
  }

  public notificationFlyout(eventId, srcCtrl){
    const eventObj: any = cloneDeep(this.analyticTags[eventId]);
    this.eventCatalogSharedService.eventCatalogTracking('Header:' + eventObj.event.event[0].eventInfo.eventName, eventId);
    return eventObj;
  }

  public trainingHubComponentObj(eventId, srcCtrl) {
    const componentObjData = srcCtrl.split(',');
    const componentObj: any = cloneDeep(this.analyticTags[eventId]);
    componentObj.event.component[0].type = componentObjData[0];
    componentObj.event.component[0].name = componentObjData[1];
    if (eventId === 'trainingHubLearnMoreFn' || eventId === 'trainingHubOpenFn'){
      this.eventCatalogSharedService.eventCatalogTracking('Training:' + componentObj.event.component[0].name,
        eventId, null, componentObjData[2]);
    }
    return componentObj;
  }

  public trackTraining(componentObj?: any) {
    // trackAnalytics defined in envConfig.properties
    if (!this.trackAnalytics || (this.skipUrls.indexOf(window.location.hash) > -1)) {
      this.pendingAnalyticsQueue.push(() => { this.trackTraining(componentObj); });
      return;
    }
    this.sendtrackTrainingBacon(componentObj);
  }

  private sendtrackTrainingBacon(componentObj?: any) {
    this.workspaceService.getCurrentWorkspace().pipe(take(1)).subscribe((currentWorkspace: any) => {
      let communityNamePrefix = '';
      communityNamePrefix = this.getCommunityNamePrefix(currentWorkspace);
      const eventObj: any = cloneDeep(this.defaultEventObj);
      const eventId = 'trainingHubPageFn';
      const pageObj: any = cloneDeep(this.analyticTags[eventId]);
      try {
        let digitalData = cloneDeep(this.digitalDataBase);
        digitalData = merge(digitalData, pageObj, componentObj);
        if (eventObj.action !== this.noAction) {
          // tslint:disable-next-line: no-string-literal
          window['digitalData'] = digitalData;
          // tslint:disable-next-line: no-string-literal
          window['analytics_et'](eventObj.action, digitalData); // use this or below line one of them
        }
      } catch (e) {
      }
    // tslint:disable-next-line: only-arrow-functions
    }, function(err) {  }); // end then()
  }

  private buildAnalyticTagsForSearchPage(eventObj, srcCtrl){
    merge(eventObj, {
      event: {
        page: {
          pageInfo: {
            'onsiteSearchTerm': '',
            'onsiteSearchResults': ''
          }
        }
      }
    });
    merge(eventObj, { event: { page: { attributes: { facetRefinement: [] } } } });
    let dimensionFilter;
    const refinements = [];
    srcCtrl[1]?.refinementValues?.forEach((refinement => {
      if (refinement.refinementName !== 'Text Search') {
        refinements.push({ refinementName: refinement.refinementName, refinementValue: refinement.refValName });
        if (srcCtrl[3]?.length === 0) {
          dimensionFilter = srcCtrl[4]?.filter
            (filterValue => filterValue.refinementName === refinement.refinementName);
        } else {
          dimensionFilter = srcCtrl[3]?.filter
            (filterValue => filterValue.refinementName === refinement.refinementName);
        }
        if (dimensionFilter?.length > 0) {
          dimensionFilter.forEach(filter => {
            if (filter.ancestorValues && filter.ancestorValues.length > 0) {
              filter.ancestorValues.forEach(ancestor => {
                refinements.push({ refinementName: filter.refinementName, refinementValue: ancestor.refValName });
              });
            }
          });
        }
      }
    }));
    let searchTerm = srcCtrl[5]?.searchTermDesc === 'add term' ? '' : srcCtrl[5]?.searchTermDesc;
    if (eventObj.event.event[0].eventInfo.eventName?.includes('Related Search Results')) {
      searchTerm = '';
      if (srcCtrl[6].includes('<mark>')) {
        searchTerm = srcCtrl[1].replace(/<mark>/g, '').replace(/<[/]mark>/g, '');
      }
      else {
        searchTerm = '';
      }
    }
    let relatedSearchSerialNumber = '';
    if(srcCtrl[5]?.AirplaneSelected) {
      const index = srcCtrl[5].AirplaneSelected.lastIndexOf('- Serial Number');
      if(index > 0) {
        relatedSearchSerialNumber = srcCtrl[5].AirplaneSelected.slice(0, index).trim();
      }
    }
    if(relatedSearchSerialNumber !== ''){
      eventObj.event.page.attributes['aircraftMSN'] = relatedSearchSerialNumber;
    }
    eventObj.event.page.attributes.facetRefinement = refinements;
    eventObj.event.page.pageInfo.onsiteSearchTerm = searchTerm;
    eventObj.event.page.pageInfo.onsiteSearchResults = srcCtrl[2];
    return eventObj;
  }
}
